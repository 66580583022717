import { forwardRef, useState } from 'react'
import IconEyeSlash from '@haiper/icons-svg/icons/outline/eye-off.svg'
import {
  inputType2Color,
  inputType2Icon,
  type2Color,
  type2Icon,
} from '@/constants'
import IconInfo from '@haiper/icons-svg/icons/outline/info-circle.svg'
import Dialog from '@/components/dialog'
import { Creation, PoNVoid } from '@/types'
import { cls, whisper } from '@/utils'
import CreationInfo from '@/components/creation-info'
import { cva, type VariantProps } from 'class-variance-authority'
import { useAtomValue } from 'jotai'
import { creationDetailsAtom } from '@/atoms'

const promptVariants = cva('flex gap-2 max-w-full items-center', {
  variants: {
    variant: {
      outline: '',
      transparent: '',
    },
    size: {
      md: 'h-10',
      sm: 'h-8',
      lg: 'h-12',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

const iconVariants = cva('shrink-0 text-icon-on-color ', {
  variants: {
    size: {
      md: 'size-6',
      sm: 'size-5',
      lg: 'size-7',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

const textVariants = cva(
  'text-text relative w-full border-solid border-border rounded-full flex items-center justify-between pl-3 bg-surface hover:bg-surface-hover active:bg-surface-hover cursor-pointer',
  {
    variants: {
      variant: {
        outline: 'border border-border pr-1',
        transparent: 'border-none border-0 pr-0',
      },
      size: {
        md: 'h-10',
        sm: 'h-8',
        lg: 'h-12',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'outline',
    },
  },
)

interface PromptProps
  extends VariantProps<typeof textVariants>,
    Pick<
      Creation,
      | 'input_type'
      | 'prompt'
      | 'create_time'
      | 'settings'
      | 'creation_id'
      | 'type'
      | 'is_public'
      | 'output_type'
      | 'config'
    > {
  className?: string
  iconClassName?: string
  onClick?: (e?: any) => PoNVoid
}

export const Prompt = forwardRef<HTMLDivElement, PromptProps>(
  (
    {
      prompt,
      input_type,
      create_time,
      settings,
      creation_id,
      className,
      type,
      size,
      config,
      variant,
      onClick,
      is_public,
      iconClassName,
    },
    ref,
  ) => {
    const [showVideoInfo, setShowVideoInfo] = useState(false)

    const ModeIcon =
      type2Icon[type] ?? inputType2Icon[input_type] ?? inputType2Icon.video

    const outerCreationMap = useAtomValue(creationDetailsAtom)

    const isPublic =
      outerCreationMap[creation_id]?.is_public ?? is_public ?? true

    const fileCount = Math.max(
      1,
      Number(
        input_type === 'image'
          ? config?.source_images?.filter(Boolean)?.length ?? 1
          : input_type === 'video'
            ? 1
            : 0,
      ) || 0,
    )

    return (
      <div
        ref={ref}
        aria-label='header'
        className={cls(promptVariants({ size, variant }), className)}
        onClick={onClick}
      >
        <div
          className={cls(
            'h-full aspect-square rounded-full flex items-center justify-center shrink-0',
            type2Color[type] ??
              inputType2Color[input_type] ??
              inputType2Color.video,
            iconClassName,
          )}
        >
          <ModeIcon
            alt={input_type}
            className={cls(iconVariants({ size }), iconClassName)}
          />
        </div>
        {input_type === 'text' && !prompt ? null : (
          <div
            className={cls(textVariants({ size, variant }))}
            onClick={() => {
              setShowVideoInfo(true)
            }}
          >
            <div className='flex items-center w-0 flex-1 select-none'>
              {input_type === 'image' ? (
                <span className='text-text shrink-0'>
                  {fileCount} image{fileCount > 1 ? 's' : ''}
                </span>
              ) : null}
              {input_type === 'video' ? (
                <span className='text-text shrink-0'>1 video</span>
              ) : null}
              {(input_type === 'image' || input_type === 'video') && prompt ? (
                <span className='shrink-0'>&nbsp;+&nbsp;</span>
              ) : null}
              {prompt ? (
                <span
                  className='w-max truncate min-w-0'
                  title={prompt}
                  aria-label='prompt-text'
                >
                  {prompt}
                </span>
              ) : null}
            </div>
            <div className='flex items-center'>
              {!isPublic && (
                <div className='size-8 flex items-center justify-center'>
                  <IconEyeSlash className='size-5 text-icon-subdued' />
                </div>
              )}
              <div className='size-8 flex items-center justify-center'>
                <IconInfo className='size-5 text-icon-subdued' />
              </div>
            </div>
          </div>
        )}
        <Dialog
          open={showVideoInfo}
          title='Creation Info'
          className={cls(
            'gap-4 flex flex-col max-h-[calc(100%-120px)] min-h-[210px]',
            className,
          )}
          footer={null}
          childrenClassName='flex flex-col min-h-0'
          onOpenChange={setShowVideoInfo}
        >
          <CreationInfo
            className='min-h-0'
            promptClassName='mb-2 text-text-subdued'
            metaClassName='mb-4'
            input_type={input_type}
            prompt={prompt}
            settings={settings}
            generationId={creation_id}
            createTime={create_time}
            config={config}
          />
        </Dialog>
      </div>
    )
  },
)

Prompt.displayName = 'Prompt'
