import { ReactNode } from 'react'
import { cls } from '@/utils'
import IconEmpty from '@/public/assets/empty.svg'

export interface EmptyProps {
  className?: string
  description?: string | ReactNode
}

export default function Empty({
  className,
  description = 'No Data',
}: EmptyProps) {
  return (
    <div
      className={cls(
        'flex flex-col justify-between items-center gap-6',
        className,
      )}
    >
      <IconEmpty className='size-16' alt='empty' />
      <div className='text-heading-lg font-bold'>{description}</div>
    </div>
  )
}
